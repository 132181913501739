import '../css/index.css';
import '../css/imprint.css';
import { FaInfo } from "react-icons/fa";
import translations from '../assets/translations.js';
import { LanguageContext } from '../LanguageProvider.js';
import { useContext } from 'react';

export function Imprint() {
    const { language } = useContext(LanguageContext);

    return(
        <div className="imprint">
            <h1>{translations[language].imprint.title}</h1>
            <div>
                <h2>{translations[language].imprint.heading}</h2>
                <p>
                    Felix Hutt IT-Solutions<br/>
                    c/o Postflex #6090<br/>
                    Emsdettener Straße 10<br/>
                    48268 Greven<br/>
                    {translations[language].imprint.country}<br/>
                    {translations[language].imprint.vat}<br/><br/>
                    <div className="imprint-info">
                        <FaInfo color="#4275ff" style={{marginRight: "8px "}} />
                        {translations[language].imprint.info}
                    </div>
                </p>
            </div>
        </div>
    )
}