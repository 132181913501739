export const translations = {
  en: {
    premierSpotlight: {
      title: "PREMIER STATS AVAILABLE NOW",
      text: "Unlock detailed insights with access to comprehensive statistics from over 550K players in the VALORANT Premier system.",
      button: "Get started",
    },
    discordBotSpotlight: {
      title: "Meet the Valolytics Discord Bot",
      text: "The Valolytics Discord Bot allows you to track and analyze both your personal and team stats. Dive into detailed map-specific insights with heatmaps, create eye-catching LFT posts for social media, and much more.",
      button: "Invite Valolytics Discord Bot",
      carousel: {
        heatmap: {
          info: "Analyze your performance with heatmaps.",
          howToUse: "Command: /heatmap",
        },
        lftPost: {
          info: "Elevate your professionalism with visually stunning LFT posts.",
          howToUse: "Command: /lft_post",
        },
        playerStats: {
          info: "Get detailed insights into your personal performance.",
          howToUse: "Command: /player_stats",
        },
        teamStats: {
          info: "Access comprehensive stats for your team on specific maps to identify strengths and weaknesses.",
          howToUse: "Command: /team_stats",
        },
        match: {
          info: "Review the performance of yourself and your team for any given match.",
          howToUse: "Command: /match",
        },
      },
    },
    lftValorantEmeaDiscordSpotlight: {
      title: "LARGEST LFT DATABASE",
      text: "Create your LFT today to connect with top teams and elite players, coaches, analysts, and managers worldwide. Get discovered and elevate your career!",
      button: "Search players now",
    },
    footer: {
      imprint: "Imprint",
      contact: "Contact",
      tos: "Terms of Service",
      pp: "Privacy Policy",
    },
    imprint: {
      title: "Imprint",
      heading: "Information according to § 5 TMG:",
      country: "Germany",
      vat: "VAT: DE368310754",
      info: "No packages or parcels - acceptance will be refused!",
    },
  },
  de: {
    premierSpotlight: {
      title: "PREMIER STATS JETZT VERFÜGBAR",
      text: "Schalte detaillierte Einblicke frei und erhalte Zugriff auf umfassende Statistiken von über 550.000 Spielern im VALORANT Premier-System.",
      button: "Jetzt Loslegen",
    },
    discordBotSpotlight: {
      title: "Lerne den Valolytics Discord Bot kennen",
      text: "Der Valolytics Discord Bot ermöglicht es dir, sowohl deine persönlichen als auch Team-Statistiken zu verfolgen und zu analysieren. Tauche ein in detaillierte, kartenbezogene Einblicke mit Heatmaps, erstelle auffällige LFT-Beiträge für soziale Medien und vieles mehr.",
      button: "Valolytics Discord Bot einladen",
      carousel: {
        heatmap: {
          info: "Analysiere deine Leistung mit Heatmaps.",
          howToUse: "Befehl: /heatmap",
        },
        lftPost: {
          info: "Steigere deine Professionalität mit visuell ansprechenden LFT-Beiträgen.",
          howToUse: "Befehl: /lft_post",
        },
        playerStats: {
          info: "Erhalte detaillierte Einblicke in deine persönliche Leistung.",
          howToUse: "Befehl: /player_stats",
        },
        teamStats: {
          info: "Greife auf umfassende Statistiken für dein Team auf bestimmten Karten zu, um Stärken und Schwächen zu identifizieren.",
          howToUse: "Befehl: /team_stats",
        },
        match: {
          info: "Überprüfe die Leistung von dir und deinem Team für jedes beliebige Match.",
          howToUse: "Befehl: /match",
        },
      },
    },
    lftValorantEmeaDiscordSpotlight: {
      title: "UMFANGREICHSTE LFT-DATENBANK",
      text: "Erstelle noch heute dein LFT, um dich mit erstklassigen Spielern, Trainern, Analysten und Managern weltweit zu vernetzen. Lass dich entdecken und level deine Karriere auf!",
      button: "Jetzt Top-Spieler entdecken",
    },
    footer: {
      imprint: "Impressum",
      contact: "Kontakt",
      tos: "Nutzungsbedingungen",
      pp: "Datenschutzerklärung",
    },
    imprint: {
      title: "Impressum",
      heading: "Angaben gemäß § 5 TMG:",
      country: "Deutschland",
      vat: "USt-IdNr.: DE368310754",
      info: "Keine Pakete oder Päckchen - Annahme wird verweigert!",
    },
  },
};

export default translations;
