import React, { createContext, useContext, useEffect } from "react";
import Plausible from "plausible-tracker";

const PlausibleContext = createContext();

export const PlausibleProvider = ({ children }) => {
  const plausible = Plausible({
    domain: "www.valolytics.gg",
    apiHost: "https://moon.valolytics.gg",
  });

  useEffect(() => {
    plausible.enableAutoPageviews();
    plausible.enableAutoOutboundTracking();
  }, []);

  const trackEvent = (eventName, options = {}) => {
    plausible.trackEvent(eventName, options);
  };

  return (
    <PlausibleContext.Provider value={{ trackEvent }}>
      {children}
    </PlausibleContext.Provider>
  );
};

export const usePlausible = () => {
  const context = useContext(PlausibleContext);
  if (!context) {
    throw new Error("usePlausible must be used within a PlausibleProvider");
  }
  return context;
};
